export enum ImageAssets {
  COMPLETED_GAME_SUCCESSFULLY = '../assets/images/game-completed-successfully.png',
  NOT_FOUND =  '../assets/images/not-found.png',
  ELO_DELTA = '../assets/images/elo_delta.png',
  ANSWERED_PROBLEMS = '../assets/images/answered_problems.png',
  ANSWERED_CORRECTLY = '../assets/images/correct_problems.png',
  TOTAL_POINTS = '../assets/images/total_points.png',
  GAMER =  '../assets/images/gamer.png',
  STAR = '../assets/images/star.png',
  ERROR_404 = '../assets/images/error-404.png',
  LOGO_ONLY = '../assets/images/logo512.png',
  GAME_CREATED = '../assets/images/game-created.png',
  GAME_JOINED = '../assets/images/game-joined.png',
  GAME_LEFT = '../assets/images/game-left.png',
  GAME_STARTED = '../assets/images/game-started.png',
  GAME_COMPLETED = '../assets/images/game-completed.png',
  GAME_WON = '../assets/images/game-won.png',
  ELO_GAINED = '../assets/images/elo-gained.png',
  ELO_LOST = '../assets/images/elo-loss.png',
  XP_GAINED = '../assets/images/xp-gained.png',
  EMPTY_REWARDS = '../assets/images/empty-rewards.jpeg',
  MPL_LOGO = '../assets/images/mpl-logo.png',
  PLAY_AND_EARN = '../assets/images/play-and-earn.png',
  DARK_THEME = '../assets/images/dark_theme.png',
  DEFAULT_THEME = '../assets/images/default_theme.png',
}
